<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CInput
            v-model="reportRecipient.email"
            v-on:update:value="updateItem"
            :isValid="validEmail"
            tooltip-feedback
            placeholder="Enter email"
            description="Email"
            :readonly="!allowEdit"
            v-c-tooltip="{content: tooltips.email? tooltips.email: '', delay: tooltipDelay}"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6">
        <CInput
            v-model="reportRecipient.firstName"
            v-on:update:value="updateItem"
            placeholder="Enter given name"
            description="First name"
            :readonly="!allowEdit"
            v-c-tooltip="{content: tooltips.firstName? tooltips.firstName: '', delay: tooltipDelay}"
        />
      </CCol>
      <CCol sm="6">
        <CInput
            v-model="reportRecipient.lastName"
            v-on:update:value="updateItem"
            placeholder="Enter surname"
            description="Last name"
            :readonly="!allowEdit"
            v-c-tooltip="{content: tooltips.lastName? tooltips.lastName: '', delay: tooltipDelay}"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ReportRecipientTooltips from "@/apps/partners/tooltips/report_recipient_tooltips";

export default {
  name: "ReportRecipientForm",
  props: ["reportRecipientIn"],
  data(){
    return {
      tooltips: ReportRecipientTooltips,
      tooltipDelay: 500,
    }
  },
  methods: {
    updateItem(){
      this.$emit('update-item', this.reportRecipient);
    },
    notNull(val) {
        return val ? val.length >= 1 : false
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  computed: {
    reportRecipient(){
      return this.reportRecipientIn? this.reportRecipientIn: {}
    },
    allowEdit: {
      get() {
        return this.$store.state.allowEdit
      },
      set (){
      }
    }
  },
  watch: {
    salesAgentIn() {
      this.updateItem();
    },
    salesAgent() {
      this.updateItem();
    }
  }
}
</script>