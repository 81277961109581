<template>
  <div>
    <CModal
        title="New Report Recipient"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <ReportRecipientForm
          v-bind:repor-recipient-in="reportRecipient"
          v-on:update-item="updateItem"
          v-on:error="catchError"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import ReportRecipientForm
  from "@/apps/partners/components/ReportRecipientForm";
const clonedeep = require('lodash.clonedeep')

const createReportRecipient = gql`
  mutation createReportRecipient($reportRecipient: ReportRecipientInput){
    createReportRecipient(reportRecipient: $reportRecipient) {
      nid
      email
  }
}
`

export default {
  name: "ReportRecipientAdd",
  components: {
    ReportRecipientForm
  },
  data(){
    return {
      reportRecipient: {},
      reportRecipientSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addReportRecipientModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.reportRecipientSave = data;
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createReportRecipient,
          variables: {
            reportRecipient: this.reportRecipientSave
          },
        })
        .then(res => {
          this.$emit('add-item', res.data.createReportRecipient);
          this.reportRecipient = {};
          this.reportRecipientSave = {};
          this.$store.commit('set', ['addReportRecipientModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.reportRecipient = {};
        this.reportRecipientSave = {};
        this.$store.commit('set', ['addReportRecipientModalShow', false]);
       }
    },
    catchError(event){
      this.error = event
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
